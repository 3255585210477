// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-font-family: "Roboto", "Helvetica Neue", sans-serif;

  .ion-color-flutaroGreenButton {
    --ion-color-base: #3cd656;
    --ion-color-contrast: #fff;
  }

  .ion-color-message {
    --ion-color-base: yellow;
  }

  .ion-color-declined {
    --ion-color-base: #fcc742;
    --ion-color-contrast: #fff;
  }

  .ion-color-google {
    --ion-color-base: #DB4437;
    --ion-color-contrast: #fff;
  }

  .ion-color-accepted {
    --ion-color-base: #40c1ac;
    --ion-color-contrast: #fff;
  }

  .ion-color-flutaroMain {
    --ion-color-base: #40c1ac;
    --ion-color-contrast: #fff;
  }

  .ion-color-flutaroAccentButton {
    --ion-color-base: #ff4081;
    --ion-color-contrast: #fff;
  }

  .ion-color-searchbarSearchListItem {
    --ion-color-base: #505759 !important;
    --ion-color-contrast: #fff;
    border: 1px solid #3e4345;
    border-radius: 10px;
    margin-bottom: 5px;
    background: #64666E !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  }

  .ion-color-color596163 {
    --ion-color-base: #596163;
    --ion-color-contrast: #fff;
  }

  .ion-color-colorSearchbar {
    --ion-color-base: #3e4345;
    --ion-color-contrast: #fff;
    padding-left: 0px;
    padding-right: 0px;
  }

  ion-content.feedContent .ion-color-colorSearchbar,
  ion-content.locationContent .ion-color-colorSearchbar {
    padding-left: 8px;
    padding-right: 8px;
  }

  .ion-color-colorFeedTextarea,
  .ion-color-color64666EProfile {
    --ion-color-base: #64666E;
    --ion-color-contrast: #3e4345;
  }

  .ion-color-colorIcon {
    --ion-color-base: #3e4345;
  }

  .colorIcon {
    --ion-color-base: #64666E;
    --ion-color-contrast: #3e4345;
  }
  .ion-color-colorCommentTextarea {
    --ion-color-base: #999A9F; // font-color
    --ion-color-contrast: #3e4345;
    font-style: italic;
    min-height: 50px;
  }

  .ion-color-color64666E {
    --ion-color-base: #64666E;
  }

  .ion-color-colorTransparent {
    --ion-color-base: transparent;
  }

  .ion-color-color64666EVehicle {
    --ion-color-base: #64666E;
    --ion-color-contrast: #fff;
  }

  .ion-color-colorPagePreview {
    --ion-color-base: transparent;
    --ion-color-contrast: transparent;
  }

  .ion-color-colorActivitiesList {
    --ion-color-base: #64666E;
    --ion-color-contrast: #3e4345;
  }

  .ion-color-colorFFFProfileText,
  .ion.color-colorfff {
    --ion-color-base: #fff;
  }

  .ion-color-colorPost64666E {
    --ion-color-contrast: #fff;
  }

  .ion-color-colorPostCardTitle {
    --ion-color-contrast: #fff;
    text-align: center;
    color: #fff!important;
  }

  .ion-color-colorFlutaroSlider {
    --ion-color-base: #3e4345;
    --ion-color-contrast: #d9d9d9;
  }

  .ion-color-colortransparentPost {
    --ion-color-contrast: #1e2023;
    --ion-color-base: transparent;
  }

  ion-tab-button {
    --color: #c0c0c0;
    --color-selected: #fff;
  }

  ion-toolbar, ion-tab-bar {
    --background: #596163;
  }

  ion-header {
    background-color: #596163;
  }

  ion-content.ios {
    --background: #505759;
  }
}

// Custom icons
// Overriding the ion-icon behavior
// All custom icons will start with "flutaro-" prefix
ion-icon {
  &[class*="flutaro-"] {
    // Instead of using the font-based icons
    // We're applying SVG masks
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background: currentColor;
    width: 1em;
    height: 1em;
  }

  // custom icons
  &[class*="flutaro-block"] {
    mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4gICAgPHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPiAgICA8cGF0aCBkPSJNMTIgMkM2LjQ4IDIgMiA2LjQ4IDIgMTJzNC40OCAxMCAxMCAxMCAxMC00LjQ4IDEwLTEwUzE3LjUyIDIgMTIgMnpNNCAxMmMwLTQuNDIgMy41OC04IDgtOCAxLjg1IDAgMy41NS42MyA0LjkgMS42OUw1LjY5IDE2LjlDNC42MyAxNS41NSA0IDEzLjg1IDQgMTJ6bTggOGMtMS44NSAwLTMuNTUtLjYzLTQuOS0xLjY5TDE4LjMxIDcuMUMxOS4zNyA4LjQ1IDIwIDEwLjE1IDIwIDEyYzAgNC40Mi0zLjU4IDgtOCA4eiIvPjwvc3ZnPg==);
  }

  // &[class*="flutaro-customicon2"] {
  //     mask-image: url(/assets/img/customicon2.svg);
  // }
}


// active color = #fff
// incative color = #9ba0a1

ion-tabs {
  ion-tab-button {
    ion-icon.md, ion-icon.ios, ion-icon.wp {
      background-repeat: no-repeat;
      background-position: center;
      height: 24px;
      width: 24px;
      background-size: contain;
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;

      &:before {
        display: none;
      }
    }
  }
}
