/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/* @import "./../node_modules/ionic4-auto-complete/auto-complete"; */

ion-app {
  margin-top: constant(safe-area-inset-top);
  margin-top: env(safe-area-inset-top);
  margin-top: var(--safe-area-inset-top);
}
//iOS = 43px = fix header-height
ion-app.ios .flutaroHeader {
  height: 43px!important;
}
ion-app.ios ion-router-outlet {
//  margin-top: 43px;
 top: 43px;
 position: absolute;
}
//Android = 56px = fix header-height
ion-app.md .flutaroHeader {
  height: 56px!important;
}
ion-app.md ion-router-outlet {
  margin-top: 56px!important;
}


// Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
// -- for body
// -- for post.add.modal.html --> add margin-top from iOS-statusbar to whole ion-modal
// -- for ion-app.ios ion-router-outlet (addionally the toolbar height of 40px)
// -- for offlineWarning --> add height iOS-statusbar
 @supports (padding-top: constant(safe-area-inset-top)) {
    ion-router-outlet ion-content {
      --safe-area-inset-top: constant(safe-area-inset-top);
      margin-top: calc(43px + var(--safe-area-inset-top));
    }
    ion-app.ios ion-modal,
    ion-app.ios .ion-page {
      --safe-area-inset-top: constant(safe-area-inset-top);
      margin-top: var(--safe-area-inset-top);
    }
    // special-handling for page-chat (cuz its different to all other pages)
    ion-app.ios page-chat.ion-page {
      margin-top: 0px!important;
    }
    ion-app.ios ion-modal .ion-page {
      margin-top: 0px!important;
    }
   ion-app.ios #mainContent.mainContentOfflineWarning{
     --safe-area-inset-top: constant(safe-area-inset-top);
     margin-top: calc(68px + var(--safe-area-inset-top));
   }
}


// // Browsers which fully support CSS Environment variables (iOS 11.2+).
 @supports (padding-top: env(safe-area-inset-top)) {
  ion-router-outlet ion-content {
    --safe-area-inset-top: env(safe-area-inset-top);
    margin-top: calc(43px + var(--safe-area-inset-top));
  }
    ion-app.ios ion-modal,
    ion-app.ios .ion-page {
      --safe-area-inset-top: env(safe-area-inset-top);
      margin-top: var(--safe-area-inset-top);
    }
    // special-handling for page-chat (cuz its different to all other pages)
    ion-app.ios page-chat.ion-page {
      margin-top: 0px!important;
    }
    ion-app.ios ion-modal .ion-page {
      margin-top: 0px!important;
    }
   ion-app.ios #mainContent.mainContentOfflineWarning{
     --safe-area-inset-top: env(safe-area-inset-top);
     margin-top: calc(68px + var(--safe-area-inset-top));
   }
}

// GLOBAL FLUTARO STYLES FOR GENERAL CSS CHANGES

.color-white {
  color: white!important;
}

// GLOBAL FLUTARO STYLES FOR IONIC ELEMENTS

ion-label p {
  color: #fff;
}

ion-checkbox.md {
  position: static;
  display: block;
  margin: 9px 36px 9px 4px;
  --background: #fff;
}

ion-checkbox.md .checkbox-icon {
  border-radius: 2px;
  position: relative;
  width: 16px;
  height: 16px;
  border-width: 2px;
  border-style: solid;
  border-color: #787878;
  background-color: #fff;
  -webkit-transition-duration: 280ms;
  transition-duration: 280ms;
  -webkit-transition-property: background;
  transition-property: background;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

ion-checkbox.md .checkbox-checked {
  border-color: #488aff;
  background-color: #488aff;
}

.checkbox-md .checkbox-checked .checkbox-inner {
  left: 4px;
  top: 0;
  position: absolute;
  width: 5px;
  height: 10px;
  border-width: 2px;
  border-top-width: 0;
  border-left-width: 0;
  border-style: solid;
  border-color: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-md.checkbox-disabled,
.item-md.item-checkbox-disabled ion-label {
  opacity: 0.3;
  pointer-events: none;
}

.item.item-md .checkbox-md {
  position: static;
  display: block;
  margin: 9px 36px 9px 4px;
}

.item.item-md .checkbox-md[item-right],
.item.item-md .checkbox-md[item-end] {
  margin: 11px 10px 10px 0;
}

.checkbox-md + .item-inner ion-label {
  margin-left: 0;
}

.checkbox-md-primary .checkbox-checked {
  border-color: #488aff;
  background-color: #488aff;
}

.checkbox-md-primary .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-secondary .checkbox-checked {
  border-color: #32db64;
  background-color: #32db64;
}

.checkbox-md-secondary .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-danger .checkbox-checked {
  border-color: #f53d3d;
  background-color: #f53d3d;
}

.checkbox-md-danger .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-light .checkbox-checked {
  border-color: #f4f4f4;
  background-color: #f4f4f4;
}

.checkbox-md-light .checkbox-checked .checkbox-inner {
  border-color: #000;
}

.checkbox-md-dark .checkbox-checked {
  border-color: #222;
  background-color: #222;
}

.checkbox-md-dark .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-message .checkbox-checked {
  border-color: yellow;
  background-color: yellow;
}

.checkbox-md-message .checkbox-checked .checkbox-inner {
  border-color: #000;
}

.checkbox-md-declined .checkbox-checked {
  border-color: #fcc742;
  background-color: #fcc742;
}

.checkbox-md-declined .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-google .checkbox-checked {
  border-color: #DB4437;
  background-color: #DB4437;
}

.checkbox-md-google .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-accepted .checkbox-checked {
  border-color: #40c1ac;
  background-color: #40c1ac;
}

.checkbox-md-accepted .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-flutaroMain .checkbox-checked {
  border-color: #40c1ac;
  background-color: #40c1ac;
}

.checkbox-md-flutaroMain .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-flutaroAccentButton .checkbox-checked {
  border-color: #ff4081;
  background-color: #ff4081;
}

.checkbox-md-flutaroAccentButton .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-flutaroGreenButton .checkbox-checked {
  border-color: #3cd656;
  background-color: #3cd656;
}

.checkbox-md-flutaroGreenButton .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-color505759 .checkbox-checked {
  border-color: #505759;
  background-color: #505759;
}

.checkbox-md-color505759 .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-color707372 .checkbox-checked {
  border-color: #707372;
  background-color: #707372;
}

.checkbox-md-color707372 .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.checkbox-md-color596163 .checkbox-checked {
  border-color: #596163;
  background-color: #596163;
}

.checkbox-md-color596163 .checkbox-checked .checkbox-inner {
  border-color: #fff;
}

.item {
  contain: content;
}

[icon-only] ion-icon {
  // padding: 0 0.5em;
  font-size: 1.8em;
  line-height: .67;
  pointer-events: none;
}

ion-toolbar ion-title.md,
ion-toolbar ion-title.ios {
  color: #fff;
}

ion-note.md {
  font-size: 1rem;
}

.item.md h3, .item.md h4, .item.md h5, .item.md h6 {
  font-size: 1.4rem;
  font-weight: normal;
  line-height: normal;
}

.button-md-flutaroMain {
  color: #fff;
  background-color: #40c1ac;
}

.button-full-md {
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  border-right-width: 0;
  border-left-width: 0;
}

.button-full {
  display: block;
  width: 100%;
  contain: strict;
  height: 35px;
}

.listHeaderText {
  margin: 5px;
  font-size: .9rem !important;
}

.list-md, .list-ios {
  padding-top: 0px;
  background: transparent;
}

.md ion-avatar[item-left],
.md ion-thumbnail[item-left],
.md ion-avatar[item-start],
.md ion-thumbnail[item-start] {
  margin: 8px 16px 8px 0;
}

.md ion-avatar,
.ios ion-avatar {
  min-width: 40px;
  min-height: 40px;
  max-height: 40px;
  max-width: 40px;
  margin-right: 15px;
}

ion-avatar,
ion-thumbnail {
  display: block;
  line-height: 1;
}

ion-avatar img,
ion-thumbnail img {
  display: block;
}

.item.md h2 {
  margin: 2px 0;
  font-size: .9rem;
  font-weight: normal;
}

.item.md ion-icon[item-left],
.item.md ion-icon[item-right],
.item.md ion-icon[item-start],
.item.md ion-icon[item-end] {
  margin-left: 0;
  margin-top: 11px;
  margin-bottom: 10px;
  margin-right: 13px;
}

.item.md [item-left],
.item.md [item-right],
.item.md [item-start],
.item.md [item-end] {
  margin: 15px 8px 9px 0
}

.ionIconListHeader {
  position: relative;
  top: 3px;
  display: inline-block;
  font-size: 1.2em;
}

ion-slides {
  display: block;
  width: 100%;
  height: 100%;
}

app-jobs-list {
  ion-item {
    --inner-padding-start: unset;
    --padding-start: unset;
  }

  [col-1] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    width: 8.33333%;
    max-width: 8.33333%;
  }

  [col-3] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }

  [col-8] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    width: 66.66667%;
    max-width: 66.66667%;
  }

  [col-5] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    width: 41.66667%;
    max-width: 41.66667%;
  }

  [col-5] {
    padding: 5px;
  }

  [col-7] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    width: 58.33333%;
    max-width: 58.33333%;
  }

  [col-7] {
    padding: 5px;
  }

  [col-4] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    width: 33.33333%;
    max-width: 33.33333%;
  }

  [col-4] {
    padding: 5px;
  }

  [col-12] {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }

  [col-12] {
    padding: 5px;
    text-align: left;
    margin-left: 7px;
  }
}

ion-grid {
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

ion-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

[col-2] {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.66667%;
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  width: 16.66667%;
  max-width: 16.66667%;
}

[col-2] {
  padding: 5px;
}

.col {
  padding: 5px;
  position: relative;
  width: 100%;
  margin: 0;
  min-height: 1px;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.footer-md:before {
  left: 0;
  bottom: -5px;
  background-position: left 0 top -2px;
  position: absolute;
  width: 100%;
  height: 5px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABXRSTlMUCS0gBIh/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==);
  background-repeat: repeat-x;
  content: "";
}

ion-input, ion-textarea {
  position: relative;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
}

.sc-ion-textarea-md-h, .sc-ion-textarea-ios-h {
  --padding-bottom: 0px;
  margin-top: 8px;
}



// App.scss
// App Styles
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply to the entire application. These
// styles are for the entire app and not just one component. Additionally, this
// file can hold Sass mixins, functions, and placeholder classes to be imported
// and used throughout the application.

/*Angular Material Icons Font*/
/*-------------------------------------------------- */

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(../node_modules/material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
  url(../node_modules/material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
  url(../node_modules/material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
}

.flutaroLogoNavbar {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 25px;
  margin-top: -13px;
  width: 100%;
  position: absolute;
}

.flutaroLogoSliderMenu {
  display: block;
  margin-right: auto;
  margin-left: auto;
  height: 35px;
  margin-top: -17px;
  width: 100%;
  position: absolute;
}

.warningContentDiv {
  z-index: 100!important;
  position: absolute;
  --overflow: hidden; //disable scrolling :)
  height: 26px!important;
  --safe-area-inset-top: env(safe-area-inset-top);
  top: calc(40px + var(--safe-area-inset-top));
  margin-top: calc(40px + var(--safe-area-inset-top)); // notch: add 40px
}

.GPSWarning {
  background: #ffce00;
  border-top: 3px solid #cca500;
  border-bottom: 3px solid #cca500;
  color: #fcf0f5;
  text-align: center;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(199, 37, 94, 0.5);
  -moz-box-shadow: 0px 2px 5px 0px rgba(199, 37, 94, 0.5);
  box-shadow: 0px 2px 5px 0px rgba(199, 37, 94, 0.5);
  position: absolute;
  width: 100%;
  z-index: 1;
}

.offlineWarning {
  background: #c5255d;
  border-top: 3px solid #e05988;
  border-bottom: 3px solid #e05988;
  color: #fcf0f5;
  text-align: center;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(199, 37, 94, 0.5);
  -moz-box-shadow: 0px 2px 5px 0px rgba(199, 37, 94, 0.5);
  box-shadow: 0px 2px 5px 0px rgba(199, 37, 94, 0.5);
  position: absolute;
  width: 100%;
  z-index: 1;
}

// base on noGPS.svg
.offlineWarningIconGPS {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjQ4O3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9LnN0MXtmaWxsOm5vbmU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjMyO3N0cm9rZS1taXRlcmxpbWl0OjEwO30uc3Qye2ZpbGw6bm9uZTtzdHJva2U6I0ZGRkZGRjtzdHJva2Utd2lkdGg6MjA7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fTwvc3R5bGU+PHRpdGxlPmlvbmljb25zLXY1LW88L3RpdGxlPjxsaW5lIGNsYXNzPSJzdDAiIHgxPSIyNTYiIHkxPSI5NiIgeDI9IjI1NiIgeTI9IjU2Ii8+PGxpbmUgY2xhc3M9InN0MCIgeDE9IjI1NiIgeTE9IjQ1NiIgeDI9IjI1NiIgeTI9IjQxNiIvPjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0yNTYsMTEyYy03OS41LDAtMTQ0LDY0LjUtMTQ0LDE0NHM2NC41LDE0NCwxNDQsMTQ0czE0NC02NC41LDE0NC0xNDRTMzM1LjUsMTEyLDI1NiwxMTJ6Ii8+PGxpbmUgY2xhc3M9InN0MCIgeDE9IjQxNiIgeTE9IjI1NiIgeDI9IjQ1NiIgeTI9IjI1NiIvPjxsaW5lIGNsYXNzPSJzdDAiIHgxPSI1NiIgeTE9IjI1NiIgeDI9Ijk2IiB5Mj0iMjU2Ii8+PGxpbmUgY2xhc3M9InN0MiIgeDE9IjE3LjgiIHkxPSIyMC4xIiB4Mj0iNDkzLjYiIHkyPSI0OTIiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 2;
  /* top: -41px; */
  margin-left: -25px;
}

// base on offline.svg
.offlineWarningIconConnection {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTEyIDUxMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOm5vbmU7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjMyO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDt9LnN0MXtmaWxsOiNGRkZGRkY7fS5zdDJ7ZmlsbDpub25lO3N0cm9rZTojRkZGRkZGO3N0cm9rZS13aWR0aDoyMDtzdHJva2UtbGluZWNhcDpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoxMDt9PC9zdHlsZT48dGl0bGU+aW9uaWNvbnMtdjUtcjwvdGl0bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTM1Mi4xLDI5OGMtNTEuNS00NS43LTEyOS00NS43LTE4MC41LDAiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNNDI0LjIsMjI1LjhjLTkxLjQtODUuNC0yMzMuNC04NS40LTMyNC43LDAiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNNDg1LjgsMTQwLjVjLTEyOS0xMTUuMS0zMjMuOS0xMTUuMS00NTIuOSwwIi8+PHBhdGggY2xhc3M9InN0MSIgZD0iTTI2MS44LDQyMi41Yy0yMC45LDAtMzcuOC0xNi45LTM3LjgtMzcuOHMxNi45LTM3LjgsMzcuOC0zNy44czM3LjgsMTYuOSwzNy44LDM3LjhTMjgyLjcsNDIyLjUsMjYxLjgsNDIyLjV6Ii8+PGxpbmUgY2xhc3M9InN0MiIgeDE9IjE4LjgiIHkxPSIxNi4xIiB4Mj0iNDk0LjYiIHkyPSI0ODgiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 2;
  /* top: -41px; */
  margin-left: -25px;
}

.splashScreenBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  background: radial-gradient(#3e4345, #505759) #64686a;
  z-index: 10000;
}

.splashScreenText {
  color: #fff;
  text-align: center;
  display: block;
  margin-top: 25px;
  font-size: .9rem;
}

.centeredDiv {
  width: auto;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 35%;
  -webkit-transform: translateY(-35%);
  -ms-transform: translateY(-35%);
  transform: translateY(-35%);
}

.splashScreenLogo {
  width: auto;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.spinner {
  margin: 40px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #e5e5e5;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4)
  }
  20% {
    -webkit-transform: scaleY(1.0)
  }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

.errorPageErrorBackground {
  background-color: #F6BB42;
  border: 2px solid #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  height: 40px;
  width: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
}

.errorPageErrorIcon {
  color: #fff;
  font-size: 24px;
  margin: 0px;
  margin-left: 14px;
  margin-top: 4px;
}

#splash-screen {
  opacity: 1;
  transition: opacity 0.2s;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: block;
  align-items: center;
  justify-content: center;
  background: radial-gradient(#3e4345, #505759) #64686a;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-size: 1.2rem;
}

.loginText {
  font-size: 13px;
  text-align: center;
  color: #fff;
}

.registerText {
  margin-top: 15px;
  font-size: 13px;
  text-align: center;
  color: #fff;
}

.loginText a {
  color: #fff;
}

.logDiv {
  margin-top: 50px;
  margin-bottom: 50px;
}

.logLogo {
  width: auto;
  height: 65px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.formItem {
  margin-bottom: 10px;
}

.acceptTerms,
.acceptTerms a {
  color: #fff;
  text-align: center;
  font-size: 13px;
}

ion-content.loginScreen {
  --background: radial-gradient(#3e4345, #505759) #64686a;
}

ion-item.formItem {
  --background: #6c7578;
}

.list-md[inset] .item:last-child {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.formItem .text-input {
  color: white;
}

.formItem .text-input:-ms-input-placeholder {
  color: white;
}

.formItem .text-input::-webkit-input-placeholder {
  text-indent: 0;
  color: white;
}

.fancy {
  line-height: 0.5;
  text-align: center;
  margin-top: 45px;
}

.fancy span {
  display: inline-block;
  position: relative;
  color: #636b6e;
}

.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 2px;
  border-bottom: 1px solid #636b6e;
  top: 0;
  width: 600px;
}

.fancy span:before {
  right: 100%;
  margin-right: 15px;
}

.fancy span:after {
  left: 100%;
  margin-left: 15px;
}

.headerIconGPS {
  position: absolute;
  top: 3px;
  font-size: 35px;
  right: 2px;
  float: right;
}

.iconActivated {
  color: #5cc14a !important;
}

.iconDeactivated {
  color: #d65568;
}

button[color=accepted] {
  color: #fff;
  background-color: #40c1ac;
  font-size: .9rem;
}

button[color=declined] {
  color: #fff;
  background-color: #fcc742;
  font-size: .9rem;
}

.button-md-light {
  color: #000;
  background-color: #f4f4f4;
  font-size: .9rem;
}

ion-toast {
  --button-color: #fff;
}

.item.ios ion-avatar[item-left],
.item.ios ion-thumbnail[item-left],
.item.ios ion-avatar[item-start],
.item.ios ion-thumbnail[item-start] {
  margin: 8px 16px 8px 0;
}

.item.ios h2:last-child, .item.ios h3:last-child, .item.ios h4:last-child, .item.ios h5:last-child, .item.ios h6:last-child, .item.ios p:last-child {
  margin-bottom: 0;
}

.item.ios h2 {
  margin: 0 0 2px;
  font-size: 1rem;
  font-weight: normal;
}

.item.item.ios ion-checkbox.ios {
  position: static;
  display: block;
  margin: 8px 16px 8px 2px;
}

//remove custom height for toolbar
// ion-toolbar {
//   height: 3.4rem;
// }


// maybe fixes the iOS native bug with the toolbar?!
ion-app.ios ion-toolbar.appToolbarMain {
  position: absolute;
}

ion-tabs ion-tab-bar {
  border-color: #414748;
  background-color: #596163;
}

ion-toolbar ion-title.title-ios,
ion-toolbar ion-title.title-md {
  color: #fff;
}

ion-item.ios {
  --border-color: #64666e;
}

button[color="light"] {
  color: #000;
  background-color: #f4f4f4;
}

button[color="light"]:hover:not(.disable-hover) {
  background-color: #f4f4f4;
}

button[color="light"].activated {
  background-color: #e0e0e0;
  opacity: 1;
}

button[color="light"] .button-effect {
  background-color: #000;
}

[icon-left] ion-icon, [icon-start] ion-icon {
  font-size: 1.4em;
  line-height: .67;
  pointer-events: none;
  padding-right: 0.3em;
}

ion-item-options:not([icon-left]) button:not([icon-only]) ion-icon, ion-item-options:not([icon-start]) .button:not([icon-only]) ion-icon {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0.3em;
  float: left;
}

ion-button.ion-color-flutaroAccentButton.md {
  color: #fff;
  // background-color: #ff4081;
}

ion-button.ion-color-flutaroAccentButton.md:hover:not(.disable-hover) {
  // background-color: #ff4081;
}

ion-button.ion-color-flutaroAccentButton.md.activated {
  // background-color: #eb3b77;
  opacity: 1;
}

.validation-errors {
  margin-bottom: 10px;
  color: red;
  font-size: .8rem;
}

.form-error {
  font-size: .9rem;
  color: red;
}

ion-toast.appUpdateNofication {
  // --border-radius: 5px;
  --border-width: 2px;
  --border-style: double;
  --border-color: #4A89DC;
  margin-right: 5px;
}

ion-toast.appUpdateNofication {
  --border-radius: 0.65rem;
  --border-width: 2px;
  --border-style: double;
  --border-color: #4A89DC;
  margin-right: 5px;
  --background: rgba(0, 0, 0, 0.9);
  --color: #fff;
}

.listHeaderRow {
  background: #3e4345 !important;
  color: #fff;
}

// needs to be set here in global
// --> because ion-auto-complete is an own component in location-rating.component
// and CSS in file location-rating.component.scss is NOT WORKING
app-location-rating ion-auto-complete ion-searchbar input.searchbar-input,
app-post-location ion-auto-complete ion-searchbar input.searchbar-input {
  background: #3e4345 !important;
  color: #fff !important;
}

app-webpage-preview ion-input input {
  color: #fff !important;
}

ion-toolbar.sliderMenu .toolbar-background {
  background: radial-gradient(#707372, #505759) rgba(34, 34, 40, .94);
}

ion-list.flutaroMainSliderList .flutaroMainSliderList a {
  background: red;
}

ion-textarea.textareaDark textarea {
  border: 1px solid #484c4e !important;
  border-radius: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  background: #505759 !important;
  line-height: 18px !important;
  margin-bottom: 10px !important;
  height: auto;
}

// ion-card-content {
//   padding-left: 0px!important;
//   padding-right: 0px!important;
// }

app-location ion-card-content {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.deepLevel1 ion-card {
  box-shadow: none;
  background: #5a5b63 !important;
  border: 1px solid #505158 !important;
  padding: 0px;
}

.deepLevel1 ion-item {
  --ion-color-base: transparent !important;
}

// .deepLevel1 ion-item .item-native {
//   --ion-color-base: transparent !important;
//   padding-left: 0px !important;
// }

// .deepLevel1 ion-item .item-inner,
// .locationRatingFeed post-comment.deepLevel1 .item-inner {
//   padding-right: 0px !important;
// }


.likeCommentSection ion-col {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.deepLevel2 ion-card {
  box-shadow: none;
  background: #515159 !important;
  border: 1px solid #3e3f45 !important;
}

// post-comment.deepLevel1 ion-item .item-native,
// post-comment.deepLevel2 ion-item .item-native {
//   padding-left: 0px !important;
// }

post-comment.deepLevel1 {
  width: 100%;
}

ion-textarea.locationCommentTextarea textarea {
  min-height: 50px !important;
  padding-left: unset!important;;
  padding-right: unset!important;;
  -webkit-padding-start: 15px!important;;
  -webkit-padding-end: 15px!important;;
}

// for postFeed
.postFeed .deepLevel1 ion-card,
.postFeed .deepLevel2 ion-card {
  margin: 0px;
}

.postFeed .deepLevel1 ion-card ion-card-content {
  padding: 0px;
}

.postFeed post-comment.deepLevel1 {
  margin-top: 20px;
}

post-comment {
  width: 100%;
}

.locationRatingFeed post-comment.deepLevel1 ion-card {
  margin-left: 0px !important;
  margin-right: 0px !important;
}


ion-item.ionIteamNoPaddingShadow {
  --inner-padding-end: 15px;
  --padding-start: 15px;
}

.locationRatingFeed .ionIteamNoPaddingShadow {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

ion-buttons.activityButton {
  position: absolute;
}

.notifications-badge {
  background-color: #d65568;
  border-radius: 12px;
  font-size: 9px;
  line-height: 18px!important;
  position: relative!important;
  right: 5px!important;
  text-align: center!important;
  top: -7px!important;
  min-width: 19px;
  font-weight: 600;
  color: #fff;
  z-index: 1;
  box-shadow: 0 2px 5px #00000042;
  margin-right: 0px!important;
}

div.modal-wrapper {
  --background: #505759;
}

div.modal-wrapper .activityCardOnlyForIonBackdrop {
  position: absolute;
  width: calc(100% - 20px);
  top: 50px;
}

div.modal-wrapper .activityOnlyForIonBackdrop {
  position: relative;
  height: 100%;
}

div.modal-wrapper .activityOnlyForIonBackgroundCardWrapper {
  height: calc(100% - 55px);
  overflow: auto;
}

// THIS IS FOR INNER .item-native + .item-inner
div.modal-wrapper .activityOnlyForIonBackgroundCardWrapper {
  ion-item {
    --inner-padding-start: unset;
    --padding-start: unset;
    --padding-end: 0px;
    --inner-padding-end: 0px;
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-bottom: 0;
  }
}


div.modal-wrapper .activityOnlyForIonBackgroundCardWrapper app-location-rating {
  ion-item {
    --padding-start: 5px!important;
    --padding-end: 5px!important;
  }
}

// overwrite global css-class
div.modal-wrapper app-groups-modal .activityOnlyForIonBackgroundCardWrapper {
  height: auto;
}

app-groups-modal .activityOnlyForIonBackgroundCardWrapper ion-card ion-card-content{
  padding-left: 10px!important;
  padding-right: 10px!important;
}

// set height/width for +flutaroAccentButton
ion-fab-button.ion-color-flutaroAccentButton {
  height: 48px;
  width: 48px;
}

// set color for all buttons in ion-header
ion-buttons.ios ion-button,
ion-buttons.ios ion-menu-button {
  --color: rgb(66, 66, 66) !important; // HEXADOCE = #424242
}

ion-button.flutaroSend {
  background: #40c1ac;
  color: #fff !important;
  border: 2px solid;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  height: 32px;
  width: 44px;
}


ion-item.commentDivItem,
ion-item.showAddMembersDivItem {
  --border-width: 0px!important;
}

.commentDiv,
.showAddMembers {
  border: 2px solid #3e4345;
  margin-bottom: 5px;
  border-radius: 5px;
  margin-top: 5px;
  background: #64666E;
}

.deviceOffline {
  margin: 25px;
  height: 90%;
  border: 2px dashed #3e4345;
  border-radius: 10px;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.deviceOffline .deviceOfflineInner {
  position: relative;
  top: 40%;
  text-align: center;
  margin: 0;
  color: #bbbcbc;
  font-weight: 300;
  font-size: 19px;
}

// base on offlineContent.svg
.deviceOfflineIcon {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkViZW5lXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNTI4IDUyOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTI4IDUyODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+LnN0MHtmaWxsOiNDNTI1NUQ7c3Ryb2tlOiNGRkZGRkY7c3Ryb2tlLXdpZHRoOjIwO3N0cm9rZS1taXRlcmxpbWl0OjEwO30uc3Qxe2ZpbGw6bm9uZTtzdHJva2U6I0ZGRkZGRjtzdHJva2Utd2lkdGg6MjM7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLWxpbmVqb2luOnJvdW5kO30uc3Qye2ZpbGw6I0ZGRkZGRjt9PC9zdHlsZT48Y2lyY2xlIGNsYXNzPSJzdDAiIGN4PSIyNjQiIGN5PSIyNjQiIHI9IjI1NCIvPjx0aXRsZT5pb25pY29ucy12NS1yPC90aXRsZT48cGF0aCBjbGFzcz0ic3QxIiBkPSJNMzMxLjYsMzEyLjVjLTM4LjUtMzQuMi05Ni41LTM0LjItMTM0LjksMCIvPjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik0zODUuNiwyNTguNmMtNjguMy02My45LTE3NC41LTYzLjktMjQyLjgsMCIvPjxwYXRoIGNsYXNzPSJzdDEiIGQ9Ik00MzEuNiwxOTQuOGMtOTYuNS04Ni4xLTI0Mi4yLTg2LjEtMzM4LjYsMCIvPjxwYXRoIGNsYXNzPSJzdDIiIGQ9Ik0yNjQuMiw0MDUuNmMtMTUuNiwwLTI4LjMtMTIuNy0yOC4zLTI4LjNjMC0xNS42LDEyLjctMjguMywyOC4zLTI4LjNjMTUuNiwwLDI4LjMsMTIuNywyOC4zLDI4LjNDMjkyLjQsMzkyLjksMjc5LjgsNDA1LjYsMjY0LjIsNDA1LjZ6Ii8+PC9zdmc+);
  background-repeat: no-repeat;
  height: 28px;
  width: 28px;
  position: absolute;
  z-index: 2;
  top: -41px;
  margin-left: -15px;
}


// list for contact + groups
.flutaroList > .item:last-child {
  border-bottom: 2px solid #3e4345;
}

ion-item.flutaroListItem {
  border-radius: 5px;
  margin-left: 15px;
  margin-top: 5px;
  width: calc(100% - 30px);
  border: 2px solid #3e4345;
  --background: #64666E !important;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.list-md ion-item.flutaroListItem,
.list-ios ion-item.flutaroListItem,
ion-item.flutaroListItem,
.list-md > .ion-item.flutaroListItem:last-child,
.list-ios > .ion-item.flutaroListItem:last-child,
ion-list.jobModalList > ion-item:last-child {
  border-bottom: none;
}

ion-item.flutaroListItem .flutaroListItemChannelName {
  color: #fff;
}

ion-icon.flutaroListItemOpenIcon {
  color: #3e4345;
  position: absolute;
  right: 5px;
  font-size: 1.1rem;
  margin-top: 0px !important;
  z-index: 1;
}

ion-avatar.flutaroListItemLogo img {
  overflow: visible;
  border-radius: 0px;
}

.flutaroListItemUnreadMessage {
  position: absolute;
  height: 20px;
  background: #ff4081;
  width: 20px;
  border-radius: 10px;
  right: 7px;
  -webkit-box-shadow: 0 0 3px 2px rgba(255, 142, 181, 0.75);
  box-shadow: 0 0 3px 2px rgba(255, 142, 181, 0.75);
  // z-index: -1;
  top: 19px;
  right: 12px;
}

.width100 {
  width: 100%;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.marginTop20 {
  margin-top: 20px;
}

.width100WithoutScrollbarWidth {
  width: calc(100% - 20px);
}

app-cargo ion-card {
  background: linear-gradient(90deg,#3e4345 0,#64686a);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

app-cargo ion-card ion-item{
  --background: linear-gradient(90deg,#3e4345 0,#64686a);
  color: #dbdbdb;
}

app-cargo ion-card ion-item {
  color: #dbdbdb;
}

app-cargo .cargoNoteItem {
  color: #dbdbdb!important;
  margin-right: 18px;
}

// spinner design
ion-loading.flutaro-spinner {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.5)
}

.flutaro-spinner ion-spinner {
  color: #40c1ac!important;
}

.flutaro-spinner .loading-content {
  color: #505759!important;
}

.jobModalMainIcon {
  position: relative;
  top: 8px;
  margin-right: 12px;
  font-size: 2rem;
  left: 2px;
}

// Flutaro Custom Global-CSS
.flutaro-alert-content {
  color: #fff;
}

.flutaro-alert-ok-button {
  color: #fff;
  background-color: #ff4081;
}

.flutaro-app-searchbar {
  margin-top: 15px;
  margin-bottom: 15px;
}

.textRight {
  text-align: right;
}

.disabledItem {
  pointer-events: none;
  --ion-color-base: darkcyan
}

ion-content.md {
  --background: #505759;
}
ion-back-button {
  --color: #fff;
}
.ion-page.can-go-back {
  --background: radial-gradient(#707372, #505759) rgba(34, 34, 40, 0.94);
}

ion-list.flutaroMainSliderList .flutaroMainSliderList a{
  background: red;
}
